@import '../../../../styles/variables';

.container {
  width: 100%;
  box-sizing: border-box;
  background-color: $colorAccent;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.image {
  width: 100%;
  max-width: 25rem;
  height: auto;
  border-radius: $borderRadiusMedium;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  &__title {
    @include h2;
    color: $colorMain2;
  }

  &__paragraph {
    @include p1;
    color: $colorMain2;
  }
}

@media screen and (min-width: 1000px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    border-radius: $borderRadiusMedium;
    padding: 4rem;
  }

  .image {
    object-fit: cover;
    object-position: calc(50% + 4rem);
    height: 100%;
  }

  .text {
    align-items: flex-start;
    gap: 1rem;

    &__title {
      text-align: left;
    }

    &__button {
      margin-top: 3rem;
    }
  }
}

@import 'styles/variables';

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins/Poppins-LightItalic.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'AllRoundGothic';
  src: url('assets/fonts/all-round-gothic/AllRoundGothic-Demi.ttf');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 20px;
}

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

textarea {
  font-family: inherit;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
  text-decoration: none;
  cursor: url('assets/icons/cursor-el.svg'), auto;
  box-sizing: border-box;
  font-family: Poppins, 'sans-serif';
}

img {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: transparent;
}

button {
  cursor: pointer;
  background-color: transparent;
}

a {
  text-decoration: none;
}

@import '../../../../styles/variables';

.Articles {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 550px) {
  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media screen and (min-width: 1000px) {
  .content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@import '../../../styles/variables';

.wrapper {
  @include fullPage;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoColorOne {
  fill: $colorAccent;

  &__alt {
    fill: $colorAccent2;
  }
}

.logoColorTwo {
  fill: $colorAccent2;

  &__alt {
    fill: $colorAccent;
  }
}

.title {
  @include h2;
  margin-top: 1rem;
}

.description {
  @include p1;
  margin-top: 2.5rem;
  text-align: center;
}

.button {
  margin-top: 5rem;
  margin-bottom: 6.5rem;
}

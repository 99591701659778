@import '../../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4rem;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  &_question {
    color: $colorMain;
    @include h2;
    text-align: center;
  }

  &_link {
    display: flex;
  }
}

@import '../../../../styles/variables';

.Reviews {
}

.title {
  @include h2;
  margin-top: 8rem;
  color: $colorMain;
  text-align: center;
}

.reviews {
  position: relative;
  margin-bottom: 2rem;
  box-sizing: content-box;
}

.reviews_list {
  display: flex;
  gap: 1rem;
  padding: 4rem 1rem 2rem 1rem;
  box-sizing: border-box;
  overflow: scroll;

  & > .review:last-child {
    margin-right: 0.5rem;
  }
}

.arrowButton {
  position: absolute;
  display: none;
  top: 45%;

  &___left {
    left: -1.5rem;
  }

  &___right {
    transform: rotateY(180deg);
    right: -1.5rem;
  }
}

@media screen and (min-width: 1080px) {
  .reviews {
    padding: 0 2rem;
  }

  .reviews_list {
    gap: 2rem;
  }

  .arrowButton {
    display: flex;
  }
}

@import '../../../../styles/variables';

.wrapper {
  @include hoverEffect;
  flex-shrink: 0;
  width: 13.5rem;
  padding: 1.5rem 1.3rem;
  border-radius: $borderRadiusMedium;
  box-shadow: $boxShadow;

  &__skeleton {
    width: 13.5rem;
    height: 27.0525rem;
  }
}

.skeleton {
  &__client {
    transform: translate(30%, 0);
    width: 5rem;
    height: 1.5rem;
  }

  &__text {
    transform: translate(0%, 9%);
    height: 9.5rem;
    width: 100%;
  }

  &__person {
    transform: translate(0%, 51%);
    width: 5rem;
    height: 1.5rem;
  }

  &__image {
    transform: translate(0%, 62%);
    width: 100%;
    height: 6.9rem;
  }

  &__url {
    transform: translate(18%, 96%);
    width: 7rem;
    height: 1rem;
  }
}

.client {
  @include h3;
  text-align: center;
}

.text {
  @include p2;
  margin-top: 1rem;
  height: 10rem;
}

.person {
  @include p1;
  color: $colorAccent3;
}

.image {
  display: grid;
  place-content: center;
  width: 100%;
  margin-top: 1.5rem;

  &_img {
    @include h3;
    width: 100%;
    border-radius: $borderRadiusMedium;
    object-fit: cover;
  }
}

.link {
  display: grid;
  place-content: center;
  width: 100%;
  margin-top: 1.5rem;

  &_text {
    @include p4;
  }
}

@media screen and (min-width: 1000px) {
  .wrapper {
    width: 14.5rem;

    &__skeleton {
      width: 14.5rem;
      height: 28.2rem;
    }
  }

  .skeleton {
    &__client {
      transform: translate(28%, 0);
      width: 6rem;
    }

    &__text {
      transform: translate(0%, 9%);
      height: 6.7rem;
    }

    &__person {
      transform: translate(0%, 43%);
      width: 6rem;
    }

    &__image {
      transform: translate(0%, 53%);
      height: 9.6rem;
    }

    &__url {
      transform: translate(25%, 96%);
    }
  }

  .text {
    height: 10rem;
  }
}

@import '../../../styles/variables';

.ArticlePage {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
}

.headerWrapper {
  width: 100%;
  background-color: $colorAccent5;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  box-sizing: content-box;

  &_one {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  &_image {
    height: 100%;
    max-height: 14.4rem;
    width: auto;
    aspect-ratio: 3/2;

    &_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $borderRadiusMedium;
    }
  }

  &_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &_date {
      @include p4;
    }

    &_title {
      @include h1;
    }

  }

  &_description {
    margin-top: 2rem;
    @include h3
  }
}

.paragraph {
  margin-top: 2rem;

  & * {
    @include p1;
    margin-bottom: 1rem;
  }

  & * > a {
    color: $colorLink !important;
    text-decoration: underline !important;
  }

  & > h1 {
    @include h2;
  }

  & > h2 {
    @include h3;
  }

  & > h3 {
    @include p1;
  }

  & > h4 {
    @include p2;
  }

  & > h5 {
    @include p3;
  }

  & > h6 {
    @include p4;
  }

  & > p {
    @include p1;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }


  & * img {
    width: 100%;
    height: auto;
    aspect-ratio: 3/2;
    object-fit: cover;
    border-radius: $borderRadiusMedium;
  }
}

.presentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: auto;
  margin-top: 4rem;
  padding: 2rem;
  background-color: $colorAccent;
  border-radius: $borderRadiusMedium;

  &_img {
    flex-shrink: 0;
    height: 5rem;
    width: 5rem;
    object-fit: cover;
    border-radius: $borderRadiusCircle;
  }

  &_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    &_title {
      @include h2;
      color: $colorMain2;
    }

    &_subTitle {
      @include h3;
      color: $colorMain2;
    }

    &_description {
      @include p1;
      color: $colorMain2;
    }
  }
}

.moreWrapper {
  margin-top: 4rem;
  background: linear-gradient(to bottom, $colorAccent4 50%, transparent 0%);
}

.more {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &_title {
    @include h2;
    color: $colorMain;
    text-align: left;
    margin-bottom: 2rem;
  }

  &_linkToBlog {
    align-self: center;
    margin-top: 2rem;
  }
}

.skeleton {
  &_header {
    &_one {
      height: 100%;
      align-items: unset;
    }

    &_text {
      justify-content: center;
      width: 100%;
    }
  }

  &_img {
  }

  &_title {
    min-width: 100%;
    height: 4rem;
  }

  &_date {
    width: 8rem;
    height: 1rem;
  }

  &_tag {
    height: 1.5rem;
    width: 6rem;
  }

  &_description {
    height: 7rem;
    width: 100%;
  }
}

@media screen and (min-width: 700px) {
  .presentation {
    flex-direction: row;

    &_img {
      height: 10rem;
      width: 10rem;
    }

    &_text {
      align-items: flex-start;
    }
  }

  .skeleton {
    &_title {
      height: 5rem;
    }

    &_description {
      height: 4rem;
    }
  }
}


@media screen and (min-width: 1000px) {
  .header {
    &_one {
      flex-direction: row;
    }

    &_image {
      flex-shrink: 0;
    }
  }
}

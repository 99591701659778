@import '../../../../styles/variables';

.container {
  background-color: $colorAccent;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  gap: 3rem
}

.title {
  @include h2;
  text-align: center;
  color: $colorMain2;
}

.services {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  &_item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
      @include h3;
      color: $colorMain2;
      margin-top: 1rem;
    }

    &_icon {
      width: 4rem;
      height: 4rem;
    }

    &_text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100%;
      margin-top: 2rem;
      padding: 1rem;
      background-color: $colorMain2;
      color: $colorMain;
      border: solid 2px $colorMain;
      border-radius: $borderRadiusMedium;

      &__subtitle {
        @include h3;
      }

      &__description {
        @include p1;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .container {
    min-height: 0;
  }

  .services {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

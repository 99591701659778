@import '../../../../styles/variables';

.wrapper {
  &:hover .color {
    fill: $colorAccent2;
  }
}

.color {
  fill: $colorMain;
}

@mixin hoverEffect {
  @media screen and (min-width: 1000px) {
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }
}

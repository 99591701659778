@import '../../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 2rem;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &_section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.title {
  @include h2;
  text-align: center;
}

.heading {
  @include h3;
}

.subheading {
  @include p1;
}

.paragraph {
  @include p2;
}

@media screen and (min-width: 1000px) {
  .wrapper {
    margin-top: 4rem;
  }
}

@import '../../../styles/variables';

.wrapper {
  position: relative;
}

.lines {
  display: grid;
  place-content: center;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border-radius: $borderRadiusLow;

  &_item {
    display: block;
    width: 1rem;
    height: 0.15rem;
    border-radius: $borderRadiusHigh;
    background-color: black;
    transition: all 0.2s ease-in-out;
    content: '';

    &:before {
      @extend .lines_item;
      position: absolute;
      transform: translateY(-0.33rem);
    }

    &:after {
      @extend .lines_item;
      position: absolute;
      transform: translateY(0.33rem);
    }
  }
}

@import 'colors';
@import 'fonts';
@import 'mixins';

$headerHeightMobile: 4.6rem;
$headerHeightDesktop: 6rem;

$footerHeightMobile: 9.75rem;
$footerHeightDesktop: 13rem;

$zIndexOverlay: 50;
$zIndexInter: 75;
$zIndexModal: 100;

$fullScreenMinHeightMobile: calc(100vh - calc(#{$headerHeightMobile} + #{$footerHeightMobile}));
$fullScreenMinHeightDesktop: calc(100vh - calc(#{$headerHeightDesktop} + #{$footerHeightDesktop}));

$boxShadow: 0 0.15rem 0.6rem #14142333;
$borderRadiusLow: 0.25rem;
$borderRadiusMedium: 0.5rem;
$borderRadiusHigh: 1rem;
$borderRadiusCircle: 50%;

@mixin fullPage {
  min-height: $fullScreenMinHeightMobile;

  @media screen and (min-width: 1000px) {
    min-height: $fullScreenMinHeightDesktop;
  }
}

@import '../../styles/variables';

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($colorAccent4, 0.6);
  z-index: $zIndexOverlay;
}


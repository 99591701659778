@import '../../../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 5rem;
  gap: 3rem;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &_title {
    @include h3;
  }

  &_description {
    @include p2;
  }

  &_contactInfo {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.5rem;

    &_item {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      &_icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      &_text {
        @include p3;
      }
    }
  }
}

.form {
  padding: 2rem 0.75rem;
  box-shadow: $boxShadow;
  border-radius: $borderRadiusHigh;

  &_title {
    @include p3;
  }

  &_form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;

    &_buttonSpinner {
      display: flex;
      gap: 2rem;
      align-items: center;
      margin-top: 0.35rem;

      &__button {
        background-color: $colorAccent;
      }

      &__spinner {
        justify-self: center;
      }
    }

    &_serverResponse {
      @include h3;
      color: $colorAccent;

      &__error {
        color: $colorAccent2;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .wrapper {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 6rem;
    gap: 4.5rem;
  }

  .form {
    padding: 2.5rem 3.75rem;
  }

  .details {
    margin-top: 2rem;
    max-width: 21rem;
  }
}

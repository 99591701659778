@import '../../../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
}

.name {
  font-family: 'AllRoundGothic', sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: $colorAccent;
}

.title {
  display: flex;
  flex-direction: column;
  height: 4rem;

  &_static {
    @include h1;
    line-height: 1.25rem;
  }

  &_dynamic {
    @include h1;
    text-indent: 4rem;
    line-height: 2.25rem;
  }
}

.image {
  display: flex;
  max-width: 24rem;
  width: 100%;
  height: auto;

  &_img {
    width: 100%;
    height: 100%;
    border-radius: $borderRadiusMedium;
    object-fit: contain;

    &__desktop {
      display: none;
    }
  }
}

.description {
  @include p1;
  margin-top: 1.5rem;
}

.button {
  margin: 3rem auto 3rem auto;
}

@media screen and (min-width: 800px) {
  .wrapper {
    flex-direction: row;
    gap: 6.2rem;
    width: 100%;
    height: calc(100vh - #{$headerHeightDesktop});
    align-items: center;
    justify-content: space-between;
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 22rem;
    min-width: 14rem;
  }

  .button {
    margin-bottom: 0;
  }

  .image {
    max-width: 38rem;
    margin-top: 0;

    &_img {
      &__mobile {
        display: none;
      }

      &__desktop {
        display: flex;
      }
    }
  }
}

@media screen and (max-height: 900px) {
  .image {
    max-width: 28rem;
  }
}

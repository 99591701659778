@import '../../../../styles/variables';

.ArticlePreview {
  @include hoverEffect;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: $boxShadow;
  background-color: $colorMain2;
  border-radius: $borderRadiusMedium;
}

.image {
  width: 100%;
  height: auto;
  aspect-ratio: 3/2;
  object-fit: cover;
  border-radius: $borderRadiusMedium $borderRadiusMedium 0 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.title {
  @include h2;
}

.description {
  @include p1;
}

.skeleton {
  &_image {
    width: 100%;
    aspect-ratio: 3/2;
    flex-shrink: 0;
  }

  &_title {
    padding: 0 1rem;
    width: 100%;
    height: 3.5rem;
    margin: 1rem 0 0.5rem 0;
    flex-shrink: 0;
  }

  &_description {
    padding: 0 1rem;
    width: 100%;
    min-height: 5rem;
    height: 100%;
  }

  &_tag {
    padding: 0 1rem 1rem 1rem;
    width: 8rem;
    height: 2.75rem;
    flex-shrink: 0;
  }
}

@import '../../../../styles/variables';

.ProjectSteps {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.title {
  @include h2;
  text-align: center;
}

.boxes {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media screen and (min-width: 600px) {
  .boxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2.5rem;
    column-gap: 1.7rem;
  }
}


@media screen and (min-width: 800px) {
  .boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2.5rem;
    column-gap: 1.7rem;
  }
}

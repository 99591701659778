@import '../../../../styles/variables';

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colorMain;
  width: 100%;

  &_title {
    @include h2;
    padding: 3rem 0 4.5rem 0;
    text-align: center;
    color: $colorMain2;
  }
}

.listWrapper {
  display: flex;
  flex-direction: column;
  margin: -1.5rem auto 0 auto;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

@import '../../styles/variables';

.wrapper {
  max-height: 2.5rem;
  padding: 0.7rem 1.6rem;
  border-radius: $borderRadiusMedium;
  background-color: $colorMain;
  color: $colorMain2;
  box-sizing: border-box;
  @include h3;

  &:hover {
    background-color: $colorAccent3;
  }
}

@import '../../../../styles/variables';

@mixin item {
  display: grid;
  place-content: center;
  background-color: #ffffff;
  padding: 0.2rem 0.45rem;
  border-radius: $borderRadiusLow;
  box-shadow: $boxShadow;
}

.wrapper {
  @include hoverEffect;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: $borderRadiusMedium;
  box-shadow: $boxShadow;
  aspect-ratio: 3/2;

  &:hover {
    .header, .button {
      opacity: 1;
    }
  }
}

.header, .button, {
  opacity: 0;
  transition: all ease-in-out 0.3s;
}

.skeleton {
  &_name {
    transform: translate(0.8rem, 0.8rem);
    width: 7rem;
    height: 1.275rem;
  }

  &_description {
    transform: translate(0.8rem, 2.3rem);
    width: 10rem;
    height: 1rem;
  }
}

.header {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .2rem;
  padding: 0.8rem;
  transition: all ease-in-out 300ms;
  z-index: 4;

  &_title {
    @include item;
    @include h3;
    font-size: 12px;
  }

  &_description {
    @include item;
    @include p3;
    font-size: 10px;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $borderRadiusMedium;
}

.button {
  position: absolute;
  bottom: 0.8rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.4rem 0.8rem !important;
  font-size: 12px !important;
}

@media screen and (min-width: 1000px) {
  .wrapper {
    min-width: 14rem;
  }
}

@import '../../../../../styles/variables';

.FAQItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.question {
  @include p1;
  width: 100%;
  min-height: 3rem;
  justify-self: flex-start;
  text-align: left;
  padding: 1rem;
  background-color: $colorAccent4;
  color: $colorMain;
  border-radius: $borderRadiusMedium;
}

.answer {
  @include p1;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 1rem;
  color: $colorMain;
}


@import '../../../styles/variables';

.wrapper {
  height: $headerHeightMobile;
  background-color: $colorMain2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.logoLink {
  width: 1.25rem;
  height: 1rem;

  & > img {
    width: 1.25rem;
    height: 1rem;
  }
}

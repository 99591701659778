@import '../../../styles/variables';

.wrapper {
  @include fullPage;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 800px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(14rem, 100%), 1fr))
  }

}

@media screen and (min-width: 1080px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@import '../../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  padding: 1.9rem 1.5rem;
  background-color: $colorMain2;
  box-shadow: $boxShadow;
  overflow: hidden;
  z-index: $zIndexOverlay;
}

.button {
  position: relative;
  align-self: flex-end;
}

.lines {
  display: grid;
  place-content: center;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border-radius: $borderRadiusLow;

  &_item {
    display: block;
    width: 1rem;
    height: 0.15rem;
    border-radius: $borderRadiusHigh;
    transition: all 0.2s ease-in-out;
    background: transparent;
    content: '';

    &:before {
      @extend .lines_item;
      position: absolute;
      transform: rotate(-45deg);
      background: black;
    }

    &:after {
      @extend .lines_item;
      position: absolute;
      transform: rotate(45deg);
      background: black;
    }
  }
}

.mainLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.secondaryLinks {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
